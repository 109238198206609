import { useCallback, useState } from 'react';

export type ModalControl<TData = any> = {
  isOpen: boolean;
  data?: TData;
  open: (data?: TData) => void;
  close: () => void;
};

export type ModalState<TData> = {
  data: TData | undefined;
};

export function useModal<TData = any>(): ModalControl<TData> {
  const [isOpen, setIsOpen] = useState(false);
  const [{ data }, setModalState] = useState({} as ModalState<TData>);

  const open = useCallback(
    (newData?: TData) => {
      setModalState({ data: newData ?? (null as unknown as TData) });
      setIsOpen(true);
    },
    [setModalState, setIsOpen]
  );

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    isOpen,
    data,
    open,
    close,
  };
}
