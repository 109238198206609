import { ModalControl } from '@/lib/modal';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, {
  ButtonHTMLAttributes,
  FormHTMLAttributes,
  Fragment,
  PropsWithChildren,
  ReactElement,
} from 'react';

type ModalProps<TData> = {
  control: ModalControl<TData>;
  title: string;
  formProps?: Partial<FormHTMLAttributes<HTMLFormElement>>;
  renderActions?: (
    actionProps: ButtonHTMLAttributes<HTMLButtonElement>
  ) => ReactElement<any, any> | null;
  unmount?: boolean;
};

interface ModalFCType {
  <TData>(
    props: PropsWithChildren<ModalProps<TData>>,
    context?: any
  ): ReactElement<any, any> | null;
}

const Modal: ModalFCType = ({
  children,
  control: { isOpen, close },
  title,
  formProps,
  renderActions,
  unmount = true,
}) => {
  const Wrapper = formProps ? 'form' : 'div';

  return (
    <Transition.Root show={isOpen} as={Fragment} unmount={unmount}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={close}
        open={isOpen}
        unmount={unmount}
      >
        <div className="flex items-end justify-center min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden lg:inline-block lg:align-middle lg:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
            enterTo="opacity-100 translate-y-0 lg:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 lg:scale-100"
            leaveTo="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
            unmount={unmount}
          >
            <Wrapper
              {...((formProps as any) ?? {})}
              className="flex flex-col relative align-bottom bg-white lg:rounded-lg text-left shadow-xl transform transition-all min-h-screen lg:min-h-0 lg:my-8 lg:self-center lg:max-w-4xl w-full"
            >
              <section className="flex lg:flex-row-reverse p-2 lg:pl-14 items-center lg:items-start border-b lg:border-b-0 border-gray-250">
                <button
                  type="button"
                  className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                  onClick={close}
                >
                  <span className="sr-only">Close modal</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-center text-base lg:text-primary-300 lg:text-left lg:font-bold lg:text-3xl lg:mt-10">
                  {title}
                </div>
              </section>

              <section className="flex-1 p-4 lg:px-14">{children}</section>

              {renderActions && (
                <section className="sticky lg:static bottom-0 mt-6">
                  {/* button */}
                  <div className="py-3 px-4 lg:p-14 lg:pt-3 border-t border-gray-250 lg:border-t-0 bg-white flex flex-col lg:flex-row-reverse gap-4">
                    {renderActions({
                      className:
                        'w-full lg:w-auto flex items-center justify-center px-4 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-medium',
                    })}
                  </div>
                </section>
              )}
            </Wrapper>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
